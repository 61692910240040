import {
    ADD_SUPPLIER2BA,
    CREATE_SUPPLIER_COST,
    DELETE_SUPPLIER2BA,
    DELETE_SUPPLIER_COST,
    EDIT_SUPPLIER2BA,
    GET_SUPPLIER_COSTS,
    GET_SUPPLIERS2BA,
    UPDATE_SUPPLIER_COST,
    UPLOAD_SUPPLIER_LOGO,
} from "../actions/types";

const initialState = {
    suppliers2ba: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUPPLIERS2BA:
            return {
                ...state,
                suppliers2ba: action.payload
            };
        case ADD_SUPPLIER2BA:
            return {
                ...state,
                suppliers2ba: [...state.suppliers2ba, action.payload]
            };
        case EDIT_SUPPLIER2BA:
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.filter(supplier => supplier !== action.oldData).concat(action.newData)
            };
        case DELETE_SUPPLIER2BA:
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.filter(supplier => supplier.id !== action.payload)
            };
        case UPLOAD_SUPPLIER_LOGO:
            return {
                ...state,
                // suppliers2ba: state.suppliers2ba.filter(supplier => supplier.id !== action.payload.id).concat(action.payload)
            };
        case GET_SUPPLIER_COSTS: {
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.map(supplier => {
                        return supplier.id === action.payload.supplierId
                            ? {
                                ...supplier,
                                costs: action.payload.supplierCosts
                            }
                            : supplier
                    }
                )
            };
        }
        case CREATE_SUPPLIER_COST: {
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.map(supplier => {
                        return supplier.id === action.payload.supplier
                            ? {
                                ...supplier,
                                costs: [...supplier.costs, action.payload]
                            }
                            : supplier
                    }
                )
            };
        }
        case UPDATE_SUPPLIER_COST:
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.map(supplier =>
                    supplier.id === action.payload.supplier
                        ? {
                            ...supplier,
                            costs: supplier.costs.map(item => item.id === action.payload.id ? action.payload : item)
                        }
                        : supplier
                )
            };
        case DELETE_SUPPLIER_COST:
            return {
                ...state,
                suppliers2ba: state.suppliers2ba.map(supplier =>
                    supplier.id === action.payload.supplierId
                        ? {
                            ...supplier,
                            costs: supplier.costs.filter(item => item.id !== action.payload.supplierCostId)
                        }
                        : supplier
                )
            };
        default:
            return state
    }
}