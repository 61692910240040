import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {integrateOrder} from "../../actions/orders";
import {TextField, Button, Typography} from "@material-ui/core";
import Modal from 'react-modal';
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";

const useStyles = theme => {
    return ({
        modalContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            height: '70%',
            maxHeight: '700px',
            margin: 'auto',
            background: theme.palette.background.default,
            padding: '20px',
            border: '1px solid rgb(204, 204, 204)',
            position: 'absolute',
            inset: '40px'
        },
        form: {
            width: '100%',
            height: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        container: {
            width: '100%',
            height: '100%'
        },
        dropdown: {
            marginTop: '10px'
        },
        textField: {
            margin: '20px 0',
            width: '100%',
            height: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '20px',
        },
        button: {
            marginLeft: '10px',
        },
        inputRoot: {
            height: '100%',
        }
    })
};


const styles = {
    modal: {
        overlay: {
            zIndex: 10,
            backgroundColor: 'inherit'
        }
    },
    textArea: {
        height: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
    },
};

const OrderIntegrationFormModal = ({isOpen, closeModal, t, integrateOrder, classes}) => {
    const [xmlData, setXmlData] = useState('');

    const onChange = event => {
        setXmlData(event.target.value);
    };

    const onSubmit = event => {
        event.preventDefault();
        integrateOrder(xmlData);
    };

    const onClose = () => {
        setXmlData('')
        closeModal()
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={styles.modal}
            className={{
                base: `${classes.modalContent} ReactModal__Content`,
            }}
        >
            <div className={classes.container}>
                <Typography variant="h5" style={{textAlign: 'center'}}>
                    {t('ORDER_INTEGRATION')}
                </Typography>
                <form onSubmit={onSubmit} className={classes.form}>
                    <TextField
                        label={t('SCSN_XML_DATA')}
                        multiline
                        fullWidth
                        value={xmlData}
                        onChange={onChange}
                        variant="outlined"
                        required
                        inputProps={{style: styles.textArea}}
                        InputProps={{classes: {root: classes.inputRoot}}}
                        className={classes.textField}
                    />
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={onClose}
                            className={classes.button}
                        >
                            {t('CLOSE')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.button}
                        >
                            {t('SEND')}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

OrderIntegrationFormModal.propTypes = {
    integrateOrder: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withStyles(useStyles)(connect(mapStateToProps, {integrateOrder})
(withTranslation(['common'])(OrderIntegrationFormModal)));
