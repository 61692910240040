import React from 'react';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import localization from '../../common/MaterialTableLocalization';
import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FirstPage,
    LastPage,
} from "@material-ui/icons";
import {Button, Container, TextField} from "@material-ui/core";
import TenderRequestItemOffersTable from "./TenderRequestItemOffersTable";
import {connect} from "react-redux";
import {deleteTenderRequestItem} from "../../../actions/tenderRequest";
import {withStyles} from "@material-ui/core/styles";
import {TenderRequestStatus} from "../../../reducers/tenderRequest";

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
};

const LinkButton = withStyles({
    root: {
        textTransform: 'none',
        color: '#0390fc',
        textDecoration: 'underline',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(Button);

const TenderRequestItemsTable = ({
                                     deleteTenderRequestItem,
                                     status,
                                     t,
                                     items,
                                     user,
                                     selectTenderRequestItem,
                                     downloadTenderRequestItemImages,
                                     selectedSuppliersList
                                 }) => {

    const handleDownloadImages = (e, tenderRequestItemId) => {
        e.preventDefault()
        downloadTenderRequestItemImages(tenderRequestItemId)
    }

    const columns = [
        {title: 'EAN', field: 'ean', sortable: true, defaultSort: 'asc'},
        {title: t('ARTICLE_NUMBER'), field: 'article_number', sorting: true},
        {title: t('tender_module:MANUFACTURER'), field: 'manufacturer_name', sorting: true},
        {
            title: t('tender_module:MODEL_NUMBER'),
            field: 'model_number',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.model_number}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('tender_module:QUANTITY_REQUIRED'),
            field: 'quantity',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.quantity}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {title: t('tender_module:STATUS'), field: 'status', sorting: true},
        {
            title: t('tender_module:PHOTO_UPLOADED'), editable: 'never', sorting: true, render: rowData => {
                if (rowData.image_count > 0) {
                    return (
                        <LinkButton onClick={(e) => handleDownloadImages(e, rowData.id)}>
                            {rowData.image_count}
                        </LinkButton>
                    )
                } else {
                    return rowData.image_count
                }
            }
        }
    ]

    if (!user.is_supplier) {
        columns.push({
            title: t('tender_module:SUPPLIER_OFFERS'),
            field: 'photo_uploaded',
            sorting: true,
            render: (rowData) => {
                return `${rowData.offers_count} ${t('tender_module:OFFERS')}`
            }
        })
    }

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title={t('tender_module:TENDER_REQUEST_ITEMS')}
                columns={columns.map(column => ({
                    ...column,
                    cellStyle: {textAlign: 'center', ...column.cellStyle},
                    headerStyle: {textAlign: 'center', ...column.headerStyle}
                }))}
                data={items}
                options={{
                    search: false,
                    sorting: true,
                    rowStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    headerStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    actionsColumnIndex: -1
                }}
                localization={localization(t)}
                editable={status !== TenderRequestStatus.DRAFT
                    ? {}
                    : {
                        onRowDelete: item => new Promise((resolve) => {
                            deleteTenderRequestItem(item.id);
                            resolve();
                        }),
                    }}
                onRowClick={((event, rowData, togglePanel) => {
                        if (status === TenderRequestStatus.DRAFT) {
                            selectTenderRequestItem(rowData)
                        } else {
                            event.preventDefault();
                            togglePanel();
                        }
                    }
                )}
                detailPanel={[
                    {
                        tooltip: t('tender_module:TENDER_REQUEST_ITEM_OFFERS'),
                        disabled: true,
                        render: rowData => {
                            return (
                                <Container style={{maxWidth: '90%'}}>
                                    <TenderRequestItemOffersTable
                                        tenderRequestItem={rowData}
                                        offers={rowData.offers || []}
                                        tenderRequestItemStatus={rowData.status}
                                        t={t}
                                        tableIcons={tableIcons}
                                        user={user}
                                        selectedSuppliersList={selectedSuppliersList}
                                    />
                                </Container>
                            )
                        }
                    }
                ]}
            />
        </div>
    )
}


export default (connect(null, {
    deleteTenderRequestItem
})(TenderRequestItemsTable));