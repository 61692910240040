import {
    GET_ENGINES,
    DELETE_ENGINE,
    ADD_ENGINE,
    EDIT_ENGINE,
    GET_OCI,
    GET_COMPANIES,
    GET_OCI_ELEMENTS,
    GET_SEARCH_ELEMENTS,
    EDIT_ELEMENTS_MAPPING,
    DELETE_ELEMENTS_MAPPING,
    ADD_ELEMENTS_MAPPING,
    EDIT_SEARCH_PARAMS,
    DELETE_SEARCH_PARAMS,
    ADD_SEARCH_PARAMS,
    EDIT_COMPANY,
    ADD_COMPANY,
    DELETE_COMPANY,
    GET_COMPANY_ROLES,
    ADD_COMPANY_ROLE,
    EDIT_COMPANY_ROLE,
    DELETE_COMPANY_ROLE,
    GET_USER_ROLES,
    ADD_USER_ROLE,
    EDIT_USER_ROLE,
    DELETE_USER_ROLE,
    GET_LICENSES,
    ADD_LICENSE,
    EDIT_LICENSE,
    DELETE_LICENSE,
    EXECUTE_SEARCH,
    SEARCHING,
    CLEAR_SEARCH,
    TRADE_ITEMS_2BA,
    GET_COUNTRIES,
    WEBSOCKET_TRADE_ITEMS,
    DIRECT_SEARCH,
    CLEAR_DIRECT_SEARCH,
    ADD_BACKGROUND_SEARCH,
    OCI_SEARCH,
    CLEAR_ESEARCH
} from '../actions/types'


const initialState = {
    esearch: [],
    oci: [],
    companies: [],
    oci_elements: [],
    search_elements: [],
    elements_mapping: [],
    company_roles: [],
    user_roles: [],
    licenses: [],
    search_results_2ba: {
        Products: [],
        TotalFound: 0
    },
    search_results_oci: {
        Products: [],
        TotalFound: 0
    },
    searching: false,
    search_string: '',
    countries: [],
    direct_search: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ESEARCH:
            return {
                esearch: [],
                oci: [],
                companies: [],
                oci_elements: [],
                search_elements: [],
                elements_mapping: [],
                company_roles: [],
                user_roles: [],
                licenses: [],
                search_results_2ba: {
                    Products: [],
                    TotalFound: 0
                },
                search_results_oci: {
                    Products: [],
                    TotalFound: 0
                },
                searching: false,
                search_string: '',
                countries: [],
                direct_search: false,
            }
        case GET_ENGINES:
            return {
                ...state,
                esearch: action.payload
            };
        case DELETE_ENGINE:
            return {
                ...state,
                esearch: state.esearch.filter(es => es.id !== action.payload)
            };
        case ADD_ENGINE:
            return {
                ...state,
                esearch: [...state.esearch, action.payload]
            };
        case EDIT_ENGINE:
            return {
                ...state,
                esearch: state.esearch.filter(es => es !== action.oldData).concat(action.newData)
            };
        case GET_OCI:
            return {
                ...state,
                oci: action.payload
            };
        case GET_COMPANIES:
            return {
                ...state,
                companies: action.payload
            };
        case EDIT_COMPANY:
            return {
                ...state,
                companies: state.companies.filter(company => company !== action.oldData).concat(action.newData)
            };
        case ADD_COMPANY:
            return {
                ...state,
                companies: [...state.companies, action.payload]
            };
        case DELETE_COMPANY:
            return {
                ...state,
                companies: state.companies.filter(company => company.id !== action.payload)
            };
        case GET_OCI_ELEMENTS:
            return {
                ...state,
                oci_elements: action.payload
            };
        case GET_SEARCH_ELEMENTS:
            return {
                ...state,
                search_elements: action.payload
            };
        case EDIT_ELEMENTS_MAPPING:
            return {
                ...state,

                // TODO: get state updated for esearch child elements Issue #9
                // esearch: state.esearch.map(function(es){
                //     es.elements_mapping.filter(em => em.id !== action.oldData).concat(action.newData);
                // })
            };
        case DELETE_ELEMENTS_MAPPING:
            return {
                ...state,
                //TODO: same as above
            };
        case ADD_ELEMENTS_MAPPING:
            return {
                ...state,
            };
        case EDIT_SEARCH_PARAMS:
            return {
                ...state,

                // TODO: get state updated for esearch child elements Issue #9
            };
        case DELETE_SEARCH_PARAMS:
            return {
                ...state,
                //TODO: same as above
            };
        case ADD_SEARCH_PARAMS:
            //TODO: same as above
            return {
                ...state,
            };
        case GET_COMPANY_ROLES:
            return {
                ...state,
                company_roles: action.payload
            };
        case ADD_COMPANY_ROLE:
            return {
                ...state,
                company_roles: [...state.company_roles, action.payload]
            };
        case EDIT_COMPANY_ROLE:
            return {
                ...state,
                company_roles: state.company_roles.filter(role => role !== action.oldData).concat(action.newData)
            };
        case DELETE_COMPANY_ROLE:
            return {
                ...state,
                company_roles: state.company_roles.filter((role => role.id !== action.payload))
            };
        case GET_USER_ROLES:
            return {
                ...state,
                user_roles: action.payload
            };
        case ADD_USER_ROLE:
            return {
                ...state,
                user_roles: [...state.user_roles, action.payload]
            };
        case EDIT_USER_ROLE:
            return {
                ...state,
                user_roles: state.user_roles.filter(role => role !== action.oldData).concat(action.newData)
            };
        case DELETE_USER_ROLE:
            return {
                ...state,
                user_roles: state.user_roles.filter((role => role.id !== action.payload))
            };
        case GET_LICENSES:
            return {
                ...state,
                licenses: action.payload
            };
        case ADD_LICENSE:
            return {
                ...state,
                licenses: [...state.licenses, action.payload]
            };
        case EDIT_LICENSE:
            return {
                ...state,
                licenses: state.licenses.filter(lic => lic !== action.oldData).concat(action.newData)
            };
        case DELETE_LICENSE:
            return {
                ...state,
                licenses: state.licenses.filter(lic => lic.id !== action.payload)
            };
        case EXECUTE_SEARCH:
            return {
                ...state,
                search_results_2ba: {
                    Products: action.payload.Products.map(item => item === item ? {...item, TradeItems: []} : item),
                    TotalFound: action.payload.TotalFound
                },
                searching: false
            };
        case OCI_SEARCH:
            return {
                ...state,
                search_results_oci: action.payload,
                searching: false
            }
        case SEARCHING:
            return {
                ...state,
                searching: true,
                search_string: action.payload
            };
        case CLEAR_SEARCH:
            return {
                ...state,
                searching: false,
                search_string: '',
                search_results_2ba: {
                    Products: [],
                    TotalFound: 0
                },
                search_results_oci: {
                    Products: [],
                    TotalFound: 0
                }
            };
        case TRADE_ITEMS_2BA:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== action.id ? item : {
                        ...item,
                        TradeItems: action.payload,
                        BackgroundSearch: []
                    }), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case WEBSOCKET_TRADE_ITEMS:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== parseInt(action.id) ? item :
                        Object.assign({}, item, {TradeItems: action.payload.concat(item.BackgroundSearch)})
                    ), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case ADD_BACKGROUND_SEARCH:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== parseInt(action.id) ? item :
                        Object.assign({}, item, {TradeItems: item.TradeItems.concat(action.payload), BackgroundSearch: item.BackgroundSearch.concat(action.payload)})
                    ), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case DIRECT_SEARCH:
            return {
                ...state,
                direct_search: true,
                searching: true,
                search_string: action.payload
            };
        case CLEAR_DIRECT_SEARCH:
            return {
                ...state,
                direct_search: false,
            };
        default:
            return state;
    }
}