import axiosInterceptor from "../interceptor";
import {createMessage, returnErrors} from "./messages";
import {tokenConfig} from "./auth";


import {
    ADD_TO_BASKET,
    EDIT_BASKET,
    REMOVE_FROM_BASKET,
    GET_COMPANY_ORDERS,
    GET_ORDER,
    UPDATE_ORDER,
    CLEAR_ORDERS,
    COPY_ORDER,
} from "./types";

function calculateTotal(cart) {
    const priceQuantityArray = cart.map(item => item.OrderQuantity * item.NetPriceInOrderUnit);
    return priceQuantityArray.reduce((a, b) => a + b, 0).toFixed(2)
}

export const createBasket = (cartItem, user) => (dispatch, getState) => {
    const tenderRequestItemId = cartItem.tenderRequestItemId
    if (tenderRequestItemId) {
        cartItem = mapTenderRequestCartItem(cartItem)
    }

    const data = {
        basket: JSON.stringify([cartItem]),
        company: user.parent_company.id,
        user: user.id,
        order_id: 0,
        basket_total: calculateTotal([cartItem]),
    };

    if (tenderRequestItemId) {
        data.tender_request_item_id = tenderRequestItemId
    }

    axiosInterceptor
        .post('/api/orders/', data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({'addToCart': 'ADDED_TO_CART'}));
            dispatch({
                type: ADD_TO_BASKET,
                payload: res.data,
                tenderRequestItemId
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD TO BASKET
export const addToBasket = (cartItem, order) => (dispatch, getState) => {
    const tenderRequestItemId = cartItem.tenderRequestItemId
    if (tenderRequestItemId) {
        cartItem = mapTenderRequestCartItem(cartItem)
    }

    const filtered_data = order.basket.concat(cartItem);

    const data = {
        basket: JSON.stringify(filtered_data),
        basket_total: calculateTotal(filtered_data)
    };

    if (tenderRequestItemId) {
        data.tender_request_item_id = tenderRequestItemId
    }

    axiosInterceptor
        .patch(`/api/orders/${order.id}/`, data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({'addToCart': 'ADDED_TO_CART'}));
            dispatch({
                type: ADD_TO_BASKET,
                payload: res.data,
                tenderRequestItemId
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const removeFromBasket = (removed_item, basket, id) => (dispatch, getState) => {
    const filtered_data = basket.filter(item => item !== removed_item);
    const data = {
        basket: JSON.stringify(filtered_data),
        basket_total: calculateTotal(filtered_data)
    };
    axiosInterceptor
        .patch(`/api/orders/${id}/`, data, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: REMOVE_FROM_BASKET,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const editBasket = (newData, oldData, basket, id) => (dispatch, getState) => {
    const filtered_data = basket.filter(item => item !== oldData).concat(newData)
    const data = {
        basket: JSON.stringify(filtered_data),
        basket_total: calculateTotal(filtered_data)
    };
    axiosInterceptor
        .patch(`/api/orders/${id}/`, data, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: EDIT_BASKET,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getCompanyOrders = (page, pageSize, searchQuery) => (dispatch, getState) => {
    const params = {page, page_size: pageSize, search_query: searchQuery}

    axiosInterceptor
        .get('/api/orders/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => {
            dispatch({
                type: GET_COMPANY_ORDERS,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getOrder = (orderId) => (dispatch, getState) => {
    axiosInterceptor
        .get(`/api/orders/${orderId}/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_ORDER,
                payload: res.data,
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const deleteOrder = (orderId) => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/orders/${orderId}/`, tokenConfig(getState))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const updateOrder = (item) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/orders/${item.id}/`, item, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_ORDER,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const downloadXML = (order_id) => (dispatch, getState) => {
    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'application/json; application/zip';
    config['responseType'] = 'blob';
    const data = {
        language: localStorage.getItem('i18nextLng')
    }
    axiosInterceptor
        .post(`/api/orders/${order_id}/download`, data, config)
        .then(res => {
            const blob = new Blob([res.data], {type: 'application/zip'});
            const objectUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = res.headers['content-disposition'];
            link.click();
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const sendXML = (order_id) => (dispatch, getState) => {
    const data = {
        language: localStorage.getItem('i18nextLng')
    }
    axiosInterceptor
        .post(`/api/orders/${order_id}/send`, data, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({email: 'ORDER_PROCESSING_STARTED'}))
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const closeOrder = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_ORDERS,
    });
    window.location.replace('/#/')
};

export const copyOrder = (cart, user) => (dispatch, getState) => {
    const data = {
        basket: JSON.stringify(cart),
        company: user.parent_company.id,
        user: user.id,
        order_id: 0,
        basket_total: calculateTotal(cart),
    };
    axiosInterceptor
        .post('/api/orders/', data, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({'copyOrder': 'Successfully Copied Order'}));
            dispatch({
                type: COPY_ORDER,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const integrateOrder = (item) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/orders/integration`, item, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({orderProcessing: 'ORDER_UPDATED_SUCCESSFULLY'}))
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const processEciOrder = (order_id) => (dispatch, getState) => {
    axiosInterceptor
        .post(`/api/orders/${order_id}/eci/process`, null, tokenConfig(getState))
        .then(() => {
            dispatch(createMessage({eciOrderProcessing: 'ORDER_PROCESSING_STARTED'}))
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};


const mapTenderRequestCartItem = (cartItem) => {
    return {
        tender_request_item_id: cartItem.tenderRequestItemId,
        additional_costs: cartItem.additional_costs,
        Id: cartItem.id,
        TradeItemId: cartItem.article_number,
        NetPriceInOrderUnit: cartItem.net_price,
        CurrencyCode: cartItem.currency,
        OrderQuantity: cartItem.OrderQuantity,
        MinimumOrderQuantity: cartItem.min_quantity,
        DeliveryTime: cartItem.delivery_days,
        SupplierGLN: cartItem.supplier_gln,
        SupplierName: cartItem.supplier_name,
        Note: cartItem.Note,
        Description: cartItem.description,
        OrderUnitMeasureUnitCode: "C62",
        LongDescription: "",
        GTIN: "",
    }
}
