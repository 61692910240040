import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import {Container, Button, Box, Grid} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../../common/MaterialTableLocalization';
import {
    ArrowUpward,
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage,
} from "@material-ui/icons";
import {ToggleSwitch} from "../../common/ToggleSwitch";
import {clearTenderRequestData, getTenderRequests} from "../../../actions/tenderRequest";
import {TenderRequestStatus} from "../../../reducers/tenderRequest";

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
};

const useStyles = () => {
    return ({
        toggleSwitchesGrindContainer: {
            justifyContent: 'flex-end'
        }
    })
};

const StatusesToggleSwitchGridItem = ({label, statuses, status, handleToggleStatus}) => {
    return (
        <Grid item>
            <ToggleSwitch
                label={label}
                labelPlacement="start"
                checked={statuses.includes(status)}
                onChange={() => {
                    handleToggleStatus(status)
                }}
            />
        </Grid>
    )
}

const TenderRequestsTable = ({classes, t, getTenderRequests, tenderRequests, clearTenderRequestData, user}) => {
    const initialStatuses = user.is_supplier
        ? [TenderRequestStatus.OPEN, TenderRequestStatus.CLOSED]
        : [TenderRequestStatus.DRAFT, TenderRequestStatus.PENDING, TenderRequestStatus.OPEN, TenderRequestStatus.CLOSED];

    const [statuses, setStatuses] = useState(initialStatuses)
    const [showAllUsersTenders, setShowAllUsersTenders] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getTenderRequests(statuses, showAllUsersTenders);
    }, [statuses, showAllUsersTenders]);

    const handleCreateNewTenderRequest = () => {
        clearTenderRequestData()
        history.push('/tender-request')
    }

    const handleToggleStatus = (status) => {
        setStatuses(previousStatuses => {
            const index = previousStatuses.indexOf(status);
            if (index !== -1) {
                return previousStatuses.filter((_, i) => i !== index);
            } else {
                return [...previousStatuses, status];
            }
        });
    };

    const columns = [
        {title: t('tender_module:DATE'), field: 'created_at', width: 100, sorting: true},
        {title: t('tender_module:REQUEST_NUMBER'), field: 'id', sorting: true, width: 100},
        {title: t('tender_module:REQUESTED_BY'), field: 'requested_by', sorting: true, width: 150},
        {title: t('tender_module:PROJECT_NAME'), field: 'project_name', sorting: true, width: 100},
        {title: t('STATUS'), field: 'status', sorting: true, width: 100},
    ]

    if (!user.is_supplier) {
        columns.push({
            title: t('tender_module:SUPPLIER_RESPONSES_RECEIVED'),
            field: 'offers_count',
            sorting: false,
            width: 200
        })
    }

    return (
        <Container maxWidth={false}>

            {!user.is_supplier && (
                <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                    <Button variant="contained" color="primary" onClick={() => handleCreateNewTenderRequest()}>
                        {t('tender_module:CREATE_NEW_TENDER_REQUEST')}
                    </Button>
                </Box>
            )}

            <Grid container className={classes.toggleSwitchesGrindContainer} spacing={2}>
                {!user.is_supplier && (
                    <>
                        <StatusesToggleSwitchGridItem label={t('tender_module:SHOW_DRAFT')}
                                                      statuses={statuses} status={TenderRequestStatus.DRAFT}
                                                      handleToggleStatus={handleToggleStatus}/>

                        <StatusesToggleSwitchGridItem label={t('tender_module:SHOW_PENDING')}
                                                      statuses={statuses} status={TenderRequestStatus.PENDING}
                                                      handleToggleStatus={handleToggleStatus}/>
                    </>
                )}

                <StatusesToggleSwitchGridItem label={t('tender_module:SHOW_OPEN')}
                                              statuses={statuses} status={TenderRequestStatus.OPEN}
                                              handleToggleStatus={handleToggleStatus}/>

                <StatusesToggleSwitchGridItem label={t('tender_module:SHOW_CLOSED')}
                                              statuses={statuses} status={TenderRequestStatus.CLOSED}
                                              handleToggleStatus={handleToggleStatus}/>

                <Grid item>
                    <ToggleSwitch
                        label={t('tender_module:SHOW_ALL_USERS_TENDERS')}
                        labelPlacement="start"
                        checked={showAllUsersTenders}
                        onChange={() => {
                            setShowAllUsersTenders(!showAllUsersTenders)
                        }}
                    />
                </Grid>
            </Grid>

            <MaterialTable
                icons={tableIcons}
                title={t('tender_module:TENDER_HISTORY')}
                columns={columns.map(column => ({
                    ...column,
                    cellStyle: {textAlign: 'center', ...column.cellStyle},
                    headerStyle: {textAlign: 'center', ...column.headerStyle}
                }))}
                data={tenderRequests}
                options={{
                    search: false,
                    sorting: true,
                    rowStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    headerStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    pageSize: 10
                }}
                localization={localization(t)}
                onRowClick={((event, rowData) => {
                        event.preventDefault();
                        history.push(`/tender-request/${rowData.id}`)
                    }
                )}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    tenderRequests: state.tenderRequestReducer.tenderRequests,
    user: state.authReducer.user,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getTenderRequests,
    clearTenderRequestData
})(withTranslation(['common', 'tender_module'])(TenderRequestsTable)));