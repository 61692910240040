import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, Chip, Grid, MenuItem, Paper, TextField} from '@material-ui/core';
import {TenderRequestStatus} from "../../../reducers/tenderRequest";
import {ESEARCH_SUPPLIER_GLN} from "../../../utils/constants";


const useStyles = theme => {
    return ({
        flexContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        buttonsContainer: {
            display: 'flex', justifyContent: 'flex-end',
        },
        roundedField: {
            border: '1px solid',
            borderColor: '#68B5E8',
            borderRadius: '30px',
            color: '#68B5E8',
            padding: '10px',
            display: 'inline-block',
            background: 'transparent'
        },
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            paddingBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
        adornedStart: {
            flexWrap: 'wrap'
        },
        chip: {
            margin: theme.spacing(0.5),
            maxWidth: '100%'
        },
    })
};

const TenderRequestForm = ({
                               tenderRequestFormData,
                               saveTenderRequest,
                               reopenTenderRequest,
                               updateTenderRequestFormData,
                               suppliersList,
                               user,
                               isUpdatable,
                               classes,
                               t
                           }) => {

    const history = useHistory();
    const [additionalSupplierValue, setAdditionalSupplierValue] = useState('');
    const [additionalSuppliersErrorLabel, setAdditionalSuppliersErrorLabel] = useState('');

    useEffect(() => {
        if (tenderRequestFormData.suppliers.length === 0) {
            const eSearchSupplier = suppliersList.find(supplier => supplier.gln === ESEARCH_SUPPLIER_GLN);
            if (eSearchSupplier) {
                updateTenderRequestFormData({'suppliers': [eSearchSupplier.id]});
            }
        }
    }, [suppliersList]);

    const handleChange = event => {
        const {name, value} = event.target;

        if (name === 'suppliers') {
            if (value.length > 3) {
                return;
            }
        }

        updateTenderRequestFormData({[name]: value});
    };

    const handleSaveTenderRequest = event => {
        event.preventDefault();
        if (additionalSupplierValue) {
            setAdditionalSuppliersErrorLabel('ADDITIONAL_SUPPLIER_NOT_SAVED');
            return
        }
        saveTenderRequest();
    };

    const handleReopenTenderRequest = event => {
        event.preventDefault();
        reopenTenderRequest(tenderRequestFormData.id);
    };


    const handleInputChange = (event) => {
        setAdditionalSupplierValue(event.target.value.trim());
    };

    const addAdditionalSupplier = (event) => {
        if (event.key === 'Enter') {
            setAdditionalSupplierValue('');
            if (tenderRequestFormData.additional_suppliers.length > 1) {
                setAdditionalSuppliersErrorLabel('ADDITIONAL_SUPPLIERS_ERROR');
            } else {
                updateTenderRequestFormData({additional_suppliers: [...tenderRequestFormData.additional_suppliers, additionalSupplierValue.trim()]});
                setAdditionalSuppliersErrorLabel('');
            }
        }
    };

    const handleDelete = (index) => {
        const updatedAdditionalSuppliers = [...tenderRequestFormData.additional_suppliers];
        updatedAdditionalSuppliers.splice(index, 1)
        updateTenderRequestFormData({additional_suppliers: updatedAdditionalSuppliers});
        setAdditionalSuppliersErrorLabel('');
    };

    return (
        <div>
            <Box className={classes.flexContainer}>
                <Paper className={classes.roundedField} elevation={2}>
                    {tenderRequestFormData.status || TenderRequestStatus.DRAFT}
                </Paper>
                <Box className={classes.buttonsContainer}>
                    {isUpdatable ? (
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                onClick={(e) => handleSaveTenderRequest(e)}>
                            {t('SAVE')}
                        </Button>

                    ) : (
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                onClick={() => history.push('/tender-requests')}>
                            {t('EXIT')}
                        </Button>
                    )}
                    {tenderRequestFormData.status === TenderRequestStatus.CLOSED && user.is_esearch_admin &&
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                                onClick={(e) => handleReopenTenderRequest(e)}>
                            {t('tender_module:REOPEN_TENDER')}
                        </Button>
                    }
                </Box>
            </Box>

            <Paper>
                <Grid container spacing={1} className={classes.paper}>
                    <Grid item xs={6}>
                        {tenderRequestFormData.id && (
                            <>
                                <TextField className={classes.textField}
                                           label={t('tender_module:REQUEST_NUMBER')}
                                           value={tenderRequestFormData.id}
                                           variant="outlined"
                                           margin="dense"
                                           name="request_number"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('tender_module:DATE_CREATED')}
                                           value={tenderRequestFormData.created_at}
                                           variant="outlined"
                                           margin="dense"
                                           name="date"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                                <TextField className={classes.textField}
                                           label={t('tender_module:REQUESTED_BY')}
                                           value={tenderRequestFormData.requested_by}
                                           variant="outlined"
                                           margin="dense"
                                           name="requested_by"
                                           InputProps={{
                                               readOnly: true,
                                           }}
                                           InputLabelProps={{
                                               shrink: true
                                           }}
                                />
                            </>
                        )}
                        <TextField className={classes.textField}
                                   label={t('tender_module:DEPARTMENT')}
                                   value={tenderRequestFormData.department}
                                   variant="outlined"
                                   margin="dense"
                                   name="department"
                                   onChange={handleChange}
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('DELIVERY_LOCATION')}
                                   value={tenderRequestFormData.delivery_location}
                                   variant="outlined"
                                   margin="dense"
                                   name="delivery_location"
                                   onChange={handleChange}
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('CUSTOMER_REFERENCE')}
                                   value={tenderRequestFormData.reference_number}
                                   margin="dense"
                                   name="reference_number"
                                   onChange={handleChange}
                                   variant="outlined"
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={classes.textField}
                                   select
                                   fullWidth
                                   margin="dense"
                                   label={t('COST_CENTER')}
                                   name="cost_center"
                                   variant="outlined"
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                                   SelectProps={{
                                       displayEmpty: true,
                                       value: tenderRequestFormData.cost_center,
                                       onChange: handleChange
                                   }}
                        >
                            {user.cost_centers.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            ))}
                        </TextField>
                        <TextField className={classes.textField}
                                   label={t('tender_module:PROJECT_NAME')}
                                   value={tenderRequestFormData.project_name}
                                   variant="outlined"
                                   margin="dense"
                                   name="project_name"
                                   onChange={handleChange}
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: false
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:PROJECT_PART')}
                                   value={tenderRequestFormData.project_part}
                                   name="project_part"
                                   margin="dense"
                                   onChange={handleChange}
                                   variant="outlined"
                                   InputProps={{
                                       readOnly: !isUpdatable,
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: false
                                   }}
                        />
                        {!user.is_supplier && (
                            <>
                                <TextField className={classes.textField}
                                           select
                                           fullWidth
                                           margin="dense"
                                           label={t('tender_module:SELECT_SUPPLIERS')}
                                           name="suppliers"
                                           variant="outlined"
                                           InputProps={{
                                               readOnly: !isUpdatable,
                                           }}
                                           InputLabelProps={{
                                               shrink: true,
                                               required: true
                                           }}
                                           SelectProps={{
                                               multiple: true,
                                               value: tenderRequestFormData.suppliers,
                                               onChange: handleChange
                                           }}
                                >

                                    {suppliersList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>
                                    ))}
                                </TextField>
                                <TextField className={classes.textField}
                                           label={t('tender_module:ADDITIONAL_SUPPLIERS')}
                                           variant="outlined"
                                           margin="dense"
                                           value={additionalSupplierValue}
                                           onChange={handleInputChange}
                                           onKeyDown={addAdditionalSupplier}
                                           fullWidth
                                           multiline
                                           rowsMax={4}
                                           error={!!additionalSuppliersErrorLabel}
                                           helperText={additionalSuppliersErrorLabel ? t(`tender_module:${additionalSuppliersErrorLabel}`) : null}
                                           InputProps={{
                                               readOnly: !isUpdatable,
                                               classes: {
                                                   adornedStart: classes.adornedStart,
                                               },
                                               startAdornment: (
                                                   tenderRequestFormData.additional_suppliers.map((value, index) => (
                                                       <Chip className={classes.chip}
                                                             key={index}
                                                             label={value}
                                                             onDelete={() => handleDelete(index)}
                                                       />
                                                   ))
                                               ),
                                           }}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default withStyles(useStyles)(TenderRequestForm)