export const EXECUTE_SEARCH = 'EXECUTE_SEARCH';
export const OCI_SEARCH = 'OCI_SEARCH';
export const SEARCHING = 'SEARCHING';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const TRADE_ITEMS_2BA = 'TRADE_ITEMS_2BA';
export const WEBSOCKET_TRADE_ITEMS = 'WEBSOCKET_TRADE_ITEMS';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET';
export const EDIT_BASKET = 'EDIT_BASKET';
export const GET_ENGINES = 'GET_ENGINES';
export const DELETE_ENGINE = 'DELETE_ENGINE';
export const ADD_ENGINE = 'ADD_ENGINE';
export const EDIT_ENGINE = 'EDIT_ENGINE';
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const GET_USERS = 'GET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER_ENGINES = 'GET_USER_ENGINES';
export const UPDATE_USER_ENGINE = 'UPDATE_USER_EGNINE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const LOADING = 'LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_COMPANIES = 'GET_COMPANIES';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const GET_OCI = 'GET_OCI';
export const GET_OCI_ELEMENTS = 'GET_OCI_ELEMENTS';
export const GET_SEARCH_ELEMENTS = 'GET_SEARCH_ELEMENTS';
export const EDIT_ELEMENTS_MAPPING = 'EDIT_ELEMENTS_MAPPING';
export const DELETE_ELEMENTS_MAPPING = 'DELETE_ELEMENTS_MAPPING';
export const ADD_ELEMENTS_MAPPING = 'ADD_ELEMENTS_MAPPING';
export const EDIT_SEARCH_PARAMS = 'EDIT_SEARCH_PARAMS';
export const DELETE_SEARCH_PARAMS = 'DELETE_SEARCH_PARAMS';
export const ADD_SEARCH_PARAMS = 'ADD_SEARCH_PARAMS';
export const GET_COMPANY_ROLES = 'GET_COMPANY_ROLES';
export const ADD_COMPANY_ROLE = 'ADD_COMPANY_ROLE';
export const EDIT_COMPANY_ROLE = 'EDIT_COMPANY_ROLE';
export const DELETE_COMPANY_ROLE = 'DELETE_COMPANY_ROLE';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const GET_LICENSES = 'GET_LICENSES';
export const ADD_LICENSE = 'ADD_LICENSE';
export const EDIT_LICENSE = 'EDIT_LICENSE';
export const DELETE_LICENSE = 'DELETE_LICENSE';
export const ADD_BRANCH_OFFICE = 'ADD_BRANCH_OFFICE';
export const EDIT_BRANCH_OFFICE = 'EDIT_BRANCH_OFFICE';
export const DELETE_BRANCH_OFFICE = 'DELETE_BRANCH_OFFICE';
export const ADD_COST_CENTER = 'ADD_COST_CENTER';
export const EDIT_COST_CENTER = 'EDIT_COST_CENTER';
export const DELETE_COST_CENTER = 'DELETE_COST_CENTER';
export const GET_COMPANY_ORDERS = 'GET_COMPANY_ORDERS';
export const GET_ORDER = 'GET_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const COPY_ORDER = 'COPY_ORDER';
export const WEBSOCKET_CONNECTING = 'WEBSOCKET_CONNECTING';
export const WEBSOCKET_OPENED = 'WEBSOCKET_OPENED';
export const WEBSOCKET_MESSAGED = 'WEBSOCKET_MESSAGED';
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';
export const WEBSOCKET_CLOSED = 'WEBSOCKET_CLOSED';
export const CHANNEL_NAME = 'CHANNEL_NAME';
export const SET_THEME = 'SET_THEME';
export const DIRECT_SEARCH = 'DIRECT_SEARCH';
export const CLEAR_DIRECT_SEARCH = 'CLEAR_DIRECT_SEARCH';
export const ADD_BACKGROUND_SEARCH = 'ADD_BACKGROUND_SEARCH';
export const GET_SUPPLIERS2BA = 'GET_SUPPLIERS2BA';
export const ADD_SUPPLIER2BA = 'ADD_SUPPLIER2BA';
export const EDIT_SUPPLIER2BA = 'EDIT_SUPPLIER2BA';
export const DELETE_SUPPLIER2BA = 'DELETE_SUPPLIER2BA';
export const UPLOAD_SUPPLIER_LOGO = 'UPLOAD_SUPPLIER_LOGO';
export const CLEAR_ESEARCH = 'CLEAR_ESEARCH';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_TENDER_REQUESTS = 'GET_TENDER_REQUESTS';
export const GET_TENDER_REQUEST_BY_ID = 'GET_TENDER_REQUEST_BY_ID';
export const SAVE_TENDER_REQUEST = 'SAVE_TENDER_REQUEST';
export const CLEAR_TENDER_REQUEST = 'CLEAR_TENDER_REQUEST';
export const SEND_TENDER_REQUEST = 'SEND_TENDER_REQUEST';
export const REOPEN_TENDER_REQUEST = 'REOPEN_TENDER_REQUEST';
export const CREATE_TENDER_REQUEST_ITEM = 'CREATE_TENDER_REQUEST_ITEM';
export const UPDATE_TENDER_REQUEST_ITEM = 'UPDATE_TENDER_REQUEST_ITEM';
export const DELETE_TENDER_REQUEST_ITEM = 'DELETE_TENDER_REQUEST_ITEM';
export const SET_TENDER_REQUEST_ITEM = 'SET_TENDER_REQUEST_ITEM';
export const CLEAR_TENDER_REQUEST_ITEM = 'CLEAR_TENDER_REQUEST_ITEM';
export const CREATE_TENDER_REQUEST_ITEM_FROM_CSV = 'CREATE_TENDER_REQUEST_ITEM_FROM_CSV';
export const CREATE_TENDER_REQUEST_ITEM_OFFER = 'CREATE_TENDER_REQUEST_ITEM_OFFER';
export const UPDATE_TENDER_REQUEST_ITEM_OFFER = 'UPDATE_TENDER_REQUEST_ITEM_OFFER';
export const DELETE_TENDER_REQUEST_ITEM_OFFER = 'DELETE_TENDER_REQUEST_ITEM_OFFER';
export const UPLOAD_TENDER_REQUEST_ITEM_IMAGE = 'UPLOAD_TENDER_REQUEST_ITEM_IMAGE';
export const GET_TENDER_REQUEST_ITEM_OFFERS = 'GET_TENDER_REQUEST_ITEM_OFFERS';
export const GET_SUPPLIER_COSTS = 'GET_SUPPLIER_COSTS';
export const CREATE_SUPPLIER_COST = 'CREATE_SUPPLIER_COST';
export const UPDATE_SUPPLIER_COST = 'UPDATE_SUPPLIER_COST';
export const DELETE_SUPPLIER_COST = 'DELETE_SUPPLIER_COST';