import axiosInterceptor from "../interceptor";
import {createMessage, returnErrors} from "./messages";
import {getCompanies} from "./esearch";
import {REGISTER_USER_FAIL, REGISTER_USER_SUCCESS, DELETE_USER, EDIT_USER, GET_USERS, EDIT_ENGINE} from "./types";
import {tokenConfig} from "./auth";


//REGISTER USER
export const registerUser = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/auth/register', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({registerUser: 'User successfully registered'}));
            dispatch({
                type: REGISTER_USER_SUCCESS,
                payload: res.data
            });
            // TODO
            dispatch(getCompanies());
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: REGISTER_USER_FAIL
            })
        })
};

//DELETE USER
export const deleteUser = item => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/auth/users/${item.id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteUser: 'User Deleted'}));
            dispatch({
                type: DELETE_USER,
                payload: item.id
            });
            // TODO
            dispatch(getCompanies());
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

//GET USERS
export const getUsers = () => (dispatch, getState) => {
    axiosInterceptor
        .get('/api/auth/users/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_USERS,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

//EDIT USERS
export const editUser = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/auth/users/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editEngine: 'User Edited'}));
            dispatch({
                type: EDIT_USER,
                newData: res.data,
                oldData: oldData
            });
            dispatch(getCompanies());
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

