import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import localization from '../../common/MaterialTableLocalization';
import {
    createTenderRequestItemOffer,
    updateTenderRequestItemOffer,
    deleteTenderRequestItemOffer,
    getTenderRequestItemOffers
} from "../../../actions/tenderRequest";
import {createBasket, addToBasket,} from "../../../actions/orders";
import {connect} from "react-redux";
import {Checkbox, TableCell, TextField} from "@material-ui/core";
import {AddToCartToolbar} from "../common/AddToCartToolbar";
import {TenderRequestStatus} from "../../../reducers/tenderRequest";
import SelectDropdown from "../../common/SelectDropdown";
import {supportedCurrencies} from "../../../utils/constants";

const TenderRequestItemOffersTable = ({
                                          tenderRequestItem,
                                          createTenderRequestItemOffer,
                                          updateTenderRequestItemOffer,
                                          deleteTenderRequestItemOffer,
                                          getTenderRequestItemOffers,
                                          tenderRequestItemStatus,
                                          t,
                                          offers,
                                          tableIcons,
                                          user,
                                          selectedSuppliersList,
                                          createBasket,
                                          addToBasket,
                                          currentOrder,
                                      }) => {

    const [selectedItem, setSelectedItem] = useState({});

    useEffect(() => {
        getTenderRequestItemOffers(tenderRequestItem.id);
    }, []);

    const handleRowSelection = (rowData) => {
        setSelectedItem(rowData.id === selectedItem.id ? {} : rowData);
    };

    const columns = [
        {title: t('ARTICLE_NUMBER'), field: 'article_number', sorting: true},
        {
            title: t('NET_PRICE_PER_UNIT'),
            field: 'net_price',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.net_price}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('CURRENCY'),
            field: 'currency',
            sorting: true,
            render: rowData => rowData.currency,
            editComponent: ({rowData, onChange}) => (
                <SelectDropdown defaultValue={rowData.currency || supportedCurrencies[0]}
                                dropdownValues={supportedCurrencies}
                                onChange={onChange}
                                translate={t}/>
            )
        },
        {
            title: t('MIN_ORDER_QUANTITY'),
            field: 'min_quantity', type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.min_quantity}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('DELIVERY_TIME'),
            field: 'delivery_days',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 1,
                    }}
                    value={rowData.delivery_days}
                    onChange={e => onChange(e.target.value)}
                />
        },
        {
            title: t('tender_module:ADDITIONAL_COSTS'),
            field: 'additional_costs',
            type: 'numeric',
            sorting: true,
            editComponent: ({rowData, onChange}) =>
                <TextField
                    inputProps={{
                        style: {textAlign: 'left'},
                        type: 'number',
                        min: 0,
                        defaultValue: 0
                    }}
                    value={rowData.additional_costs}
                    onChange={e => onChange(e.target.value)}
                />
        }
    ]

    if (!user.is_supplier) {
        if (user.is_esearch_admin) {
            const supplierIds = offers.map(item => item.supplier);
            const filteredSuppliers = selectedSuppliersList.filter(item => !supplierIds.includes(item.id))
            columns.unshift({
                title: t('SUPPLIER_NAME'),
                field: 'supplier',
                editable: 'onAdd',
                render: rowData => rowData.supplier_name,
                editComponent: ({rowData, onChange}) => (
                    <SelectDropdown defaultValue={rowData.supplier_name}
                                    dropdownValues={filteredSuppliers}
                                    onChange={onChange}
                                    idField="id"
                                    displayField="company_name"
                    />
                )
            })
        } else {
            columns.unshift({
                title: t('SUPPLIER_NAME'),
                field: 'supplier',
                render: rowData => rowData.supplier_name,
                editable: 'never'
            })
        }
    }

    if (!user.is_supplier && tenderRequestItemStatus === TenderRequestStatus.OPEN) {
        columns.unshift({
            title: '',
            field: 'selected',
            render: (rowData) => (
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={rowData.id === selectedItem.id}
                    />
                </TableCell>
            ),
            headerStyle: {textAlign: 'center'},
            cellStyle: {textAlign: 'center'},
            editable: 'never'
        },)
    }

    return (
        <div>
            {selectedItem.id && user && !user.is_supplier && user.parent_company && ['EDI'].includes(user.parent_company.license) &&
                <AddToCartToolbar
                    currentOrder={currentOrder}
                    selectedItem={{
                        ...selectedItem,
                        tenderRequestItemId: tenderRequestItem.id,
                        description: tenderRequestItem.description,
                        MinimumOrderQuantity: selectedItem.min_quantity
                    }}
                    setSelectedItem={setSelectedItem}
                    user={user}
                    t={t}
                    createBasket={createBasket}
                    addToBasket={addToBasket}
                />}
            <MaterialTable
                title={t('tender_module:OFFERS')}
                icons={tableIcons}
                columns={columns.map(column => ({
                    ...column,
                    cellStyle: {textAlign: 'center', ...column.cellStyle},
                    headerStyle: {textAlign: 'center', ...column.headerStyle}
                }))}
                data={offers}
                options={{
                    search: false,
                    sorting: true,
                    rowStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    headerStyle: {
                        backgroundColor: 'palette.text.disabled'
                    },
                    actionsColumnIndex: -1,
                }}
                localization={localization(t)}
                editable={tenderRequestItemStatus === TenderRequestStatus.OPEN && (user.is_supplier || user.is_esearch_admin)
                    ? {
                        ...(((user.is_esearch_admin && offers.length !== selectedSuppliersList.length) || offers?.length === 0) && {
                            onRowAdd: item => new Promise((resolve) => {
                                createTenderRequestItemOffer({...item, tender_request_item: tenderRequestItem.id});
                                resolve();
                            })
                        }),
                        onRowUpdate: item => new Promise((resolve) => {
                            updateTenderRequestItemOffer(item);
                            resolve();
                        }),
                        onRowDelete: item => new Promise((resolve) => {
                            deleteTenderRequestItemOffer(item.id, item.tender_request_item);
                            resolve();
                        }),
                    }
                    : {}}
                onRowClick={(e, rowData) => {
                    if (!user.is_supplier && tenderRequestItemStatus === TenderRequestStatus.OPEN) {
                        handleRowSelection(rowData)
                    }
                }}
            />
        </div>
    )
}


const mapStateToProps = state => ({
    currentOrder: state.ordersReducer.current_order,
});


export default (connect(mapStateToProps, {
    createTenderRequestItemOffer,
    updateTenderRequestItemOffer,
    deleteTenderRequestItemOffer,
    getTenderRequestItemOffers,
    createBasket,
    addToBasket
})(TenderRequestItemOffersTable));