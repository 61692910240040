import React, {createRef} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box, Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography} from '@material-ui/core';
import {CloudDownload, ImageSearch} from "@material-ui/icons";

const useStyles = theme => {
    return ({
        container: {
            marginBottom: "50px"
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: "10px",
        },
        roundedField: {
            border: '1px solid',
            borderColor: '#68B5E8',
            borderRadius: '30px',
            color: '#68B5E8',
            padding: '10px',
            display: 'inline-block',
            background: 'transparent'
        },
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            marginBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
    })
};

const TenderRequestItemsForm = ({
                                    tenderRequestItemFormData,
                                    updateTenderRequestItem,
                                    updateTenderRequestItemFormData,
                                    tenderRequestFormData,
                                    sendTenderRequest,
                                    status,
                                    createTenderRequestItem,
                                    uploadTenderRequestItemImage,
                                    downloadTenderRequestItemImages,
                                    clearForm,
                                    returnToHandleRequest,
                                    numberOfItems,
                                    classes,
                                    t
                                }) => {

    const chosenImageRef = createRef();

    const handleChange = event => {
        const {name, value} = event.target;
        updateTenderRequestItemFormData({[name]: value || null});
    };

    const handleReturnToTenderRequest = () => {
        returnToHandleRequest();
    };

    const handleSendTenderRequest = event => {
        event.preventDefault();
        sendTenderRequest(tenderRequestFormData.id);
    };

    const handleCreateTenderRequestItem = event => {
        event.preventDefault();
        createTenderRequestItem();
    };

    const handleUpdateTenderRequestItem = event => {
        event.preventDefault();
        updateTenderRequestItem(tenderRequestItemFormData);
    };

    const submitFile = () => {
        uploadTenderRequestItemImage(tenderRequestItemFormData.id, chosenImageRef.current.files[0]);
        chosenImageRef.current.value = null
    };
    const showDialog = () => {
        chosenImageRef.current.click();
    };

    return (
        <div className={classes.container}>
            <Box className={classes.flexContainer}>
                <Paper className={classes.roundedField} elevation={2}>
                    {status}
                </Paper>
                <Box className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" style={{marginRight: '10px'}}
                            onClick={() => handleReturnToTenderRequest()}>
                        {t('tender_module:RETURN_TO_TENDER_REQUEST')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={(e) => handleSendTenderRequest(e)}
                            disabled={numberOfItems === 0}>
                        {t('tender_module:SEND_TENDER')}
                    </Button>
                </Box>
            </Box>

            <Paper>
                <Grid container spacing={1} className={classes.paper}>
                    <Grid item xs={6}>
                        {tenderRequestItemFormData.id &&
                            <TextField className={classes.textField}
                                       label={t('tender_module:ITEM_ID')}
                                       value={tenderRequestItemFormData.id}
                                       variant="outlined"
                                       margin="dense"
                                       name="request_number"
                                       InputProps={{
                                           readOnly: true,
                                       }}
                                       InputLabelProps={{
                                           shrink: true
                                       }}
                            />
                        }
                        <TextField className={classes.textField}
                                   label={'EAN'}
                                   value={tenderRequestItemFormData.ean || null}
                                   variant="outlined"
                                   margin="dense"
                                   name="ean"
                                   onChange={handleChange}
                                   type="number"
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('ARTICLE_NUMBER')}
                                   value={tenderRequestItemFormData.article_number || ''}
                                   variant="outlined"
                                   margin="dense"
                                   name="article_number"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:MANUFACTURER_NAME')}
                                   value={tenderRequestItemFormData.manufacturer_name || ''}
                                   variant="outlined"
                                   margin="dense"
                                   name="manufacturer_name"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:MODEL_NUMBER')}
                                   value={tenderRequestItemFormData.model_number || ""}
                                   variant="outlined"
                                   margin="dense"
                                   name="model_number"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true
                                   }}
                        />
                        <TextField className={classes.textField}
                                   label={t('tender_module:QUANTITY_REQUIRED')}
                                   value={tenderRequestItemFormData.quantity || ""}
                                   variant="outlined"
                                   margin="dense"
                                   name="quantity"
                                   onChange={handleChange}
                                   type="number"
                                   inputProps={{
                                       min: 1
                                   }}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                        {tenderRequestItemFormData.id &&
                            <TextField className={classes.textField}
                                       label={t('tender_module:UPLOAD_PHOTO')}
                                       variant="outlined"
                                       InputLabelProps={{
                                           shrink: true,
                                           required: true
                                       }}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <Typography>{tenderRequestItemFormData.image_count} {t('tender_module:PHOTOS_UPLOADED')}</Typography>
                                               </InputAdornment>
                                           ),
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <input
                                                       accept='image/*'
                                                       style={{display: 'none'}}
                                                       type='file'
                                                       ref={chosenImageRef}
                                                       onChange={() => submitFile("")}
                                                   />
                                                   <IconButton onClick={() => showDialog()}
                                                               disabled={tenderRequestItemFormData.image_count >= 3}>
                                                       <ImageSearch/>
                                                   </IconButton>
                                                   <IconButton
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           downloadTenderRequestItemImages(tenderRequestItemFormData.id)
                                                       }}
                                                       disabled={tenderRequestItemFormData.image_count === 0}>
                                                       <CloudDownload/>
                                                   </IconButton>
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={classes.textField}
                                   label={t('tender_module:DESCRIPTION')}
                                   value={tenderRequestItemFormData.description || ''}
                                   multiline
                                   rows={4}
                                   variant="outlined"
                                   margin="dense"
                                   name="description"
                                   onChange={handleChange}
                                   InputLabelProps={{
                                       shrink: true,
                                       required: true
                                   }}
                        />
                    </Grid>
                </Grid>
                <Box className={classes.buttonsContainer}>
                    {tenderRequestItemFormData.id ?
                        <div>
                            <Button variant="contained" color="primary"
                                    style={{marginBottom: "10px", marginRight: "10px"}}
                                    onClick={() => clearForm()}>
                                {t('tender_module:CLEAR_ITEM')}
                            </Button>
                            <Button variant="contained" color="primary" style={{marginBottom: "10px"}}
                                    onClick={(e) => handleUpdateTenderRequestItem(e)}>
                                {t('tender_module:UPDATE_ITEM')}
                            </Button>
                        </div>
                        :
                        <Button variant="contained" color="primary" style={{marginBottom: "10px"}}
                                onClick={(e) => handleCreateTenderRequestItem(e)}>
                            {t('tender_module:ADD_ITEM')}
                        </Button>
                    }
                </Box>
            </Paper>
        </div>
    )
}

export default withStyles(useStyles)(TenderRequestItemsForm)