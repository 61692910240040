import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';

import {
    getEngines,
    deleteEngine,
    editEngine,
    addEngine,
    getOCI,
    getCompanies,
    getSearchElements,
    getOCIElements,
    addElementsMapping,
    editElementsMapping,
    deleteElementsMapping,
    addSearchParams,
    deleteSearchParams,
    editSearchParams
} from "../../actions/esearch";

import SelectOCI from './SelectOCI';
import SelectCompany from './SelectCompany';
import SelectOCIElement from "./SelectOCIElement";
import SelectEsearchElement from './SelectEsearchElement'

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    Settings,
    SettingsOutlined,
    SettingsEthernet
} from "@material-ui/icons";


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        }
    })
};

class BackgroundSearch extends Component {
    static propTypes = {
        esearch: PropTypes.array.isRequired,
        oci: PropTypes.array.isRequired,
        companies: PropTypes.array.isRequired,
        oci_elements: PropTypes.array.isRequired,
        search_elements: PropTypes.array.isRequired,
        getEngines: PropTypes.func.isRequired,
        deleteEngine: PropTypes.func.isRequired,
        editEngine: PropTypes.func.isRequired,
        addEngine: PropTypes.func.isRequired,
        getOCI: PropTypes.func.isRequired,
        getCompanies: PropTypes.func.isRequired,
        getSearchElements: PropTypes.func.isRequired,
        getOCIElements: PropTypes.func.isRequired,
        addElementsMapping: PropTypes.func.isRequired,
        editElementsMapping: PropTypes.func.isRequired,
        deleteElementsMapping: PropTypes.func.isRequired,
        addSearchParams: PropTypes.func.isRequired,
        editSearchParams: PropTypes.func.isRequired,
        deleteSearchParams: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.getEngines();
        this.props.getOCI();
        this.props.getCompanies();
        this.props.getOCIElements();
        this.props.getSearchElements();
    }

    render() {
        const {classes, t} = this.props;
        return (
            <Container maxWidth={false}>
                <MaterialTable
                    icons={tableIcons}
                    title={t('background_search:TITLE')}
                    columns={[
                        {
                            title: t('background_search:COMPANY'), field: 'parent_company', defaultSort: 'asc',
                            render: rowData => rowData.parent_company,
                            editComponent: props => (
                                <SelectCompany value={props.value} onChange={props.onChange}
                                               companies={this.props.companies}/>
                            ),
                            initialEditValue: [],
                            cellStyle: {whiteSpace: 'nowrap'}
                        },
                        {title: t('background_search:DESCRIPTION'), field: 'description'},
                        {title: t('background_search:ENABLED'), field: 'enabled', type: 'boolean'},
                        {
                            title: t('background_search:OCI_VERSION'), field: 'oci_version',
                            render: rowData => rowData.oci_version,
                            editComponent: props => (
                                <SelectOCI value={props.value} onChange={props.onChange}
                                           oci={this.props.oci}/>
                            ),
                            initialEditValue: [],
                            cellStyle: {whiteSpace: 'nowrap'}
                        },
                        {title: t('background_search:EXTERNAL_URL'), field: 'external_url'},
                        {title: t('background_search:SERVICE'), field: 'service'},
                        {title: t('background_search:OCI_TEMPLATE'), field: 'oci_template'},
                        {
                            title: t('background_search:IS_SUPPLIER'),
                            field: 'is_supplier',
                            initialEditValue: false,
                            type: 'boolean'
                        },
                        {title: t('background_search:CATALOG_LOGIN'), field: 'catalog_login', type: 'boolean'},
                        {title: t('background_search:USERNAME'), field: 'username'},
                        {title: t('background_search:PASSWORD'), field: 'password'}
                    ]}
                    options={{
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        }
                    }}
                    data={this.props.esearch}
                    editable={{
                        onRowAdd: newData => new Promise((resolve, reject) => {
                            this.props.addEngine(newData);
                            resolve();
                        }),
                        onRowDelete: oldData => new Promise((resolve, reject) => {
                            this.props.deleteEngine(oldData);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editEngine(newData, oldData);
                            }
                            resolve();
                        }),
                    }}
                    localization={localization(t)}
                    detailPanel={[
                        {
                            tooltip: 'Elements Mapping',
                            render: rowData => {
                                return (
                                    <Container maxWidth='lg'>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title='Esearch Mappings'
                                            columns={[
                                                {
                                                    title: 'Esearch Element',
                                                    field: 'esearch_element',
                                                    render: rowData =>
                                                        <span>{t(rowData.esearch_element)}</span>,
                                                    editComponent: props => (
                                                        <SelectEsearchElement value={props.value}
                                                                              onChange={props.onChange}
                                                                              search_elements={this.props.search_elements}
                                                                              t={t}/>
                                                    ),
                                                    initialEditValue: [],
                                                    cellStyle: {whiteSpace: 'nowrap'}
                                                },
                                                {
                                                    title: 'OCI Element', field: 'oci_element',
                                                    render: rowData => rowData.oci_element,
                                                    editComponent: props => (
                                                        <SelectOCIElement value={props.value} onChange={props.onChange}
                                                                          oci_elements={this.props.oci_elements}/>
                                                    ),
                                                    initialEditValue: [],
                                                    cellStyle: {whiteSpace: 'nowrap'}
                                                },
                                                {
                                                    title: 'Search Engine',
                                                    field: 'search_engine',
                                                    hidden: false,
                                                    initialEditValue: rowData.id
                                                }
                                            ]}
                                            data={rowData.elements_mapping}
                                            options={{
                                                emptyRowsWhenPaging: false,
                                                search: false,
                                            }}
                                            localization={localization(t)}
                                            editable={{
                                                onRowAdd: newData => new Promise((resolve, reject) => {
                                                    this.props.addElementsMapping(newData);
                                                    resolve();
                                                }),
                                                onRowDelete: oldData => new Promise((resolve, reject) => {
                                                    this.props.deleteElementsMapping(oldData);
                                                    resolve();
                                                }),
                                                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                    const changed = deepCopy(oldData);
                                                    delete changed.tableData;
                                                    if (!isEqual(newData, changed)) {
                                                        this.props.editElementsMapping(newData, oldData);
                                                    }
                                                    resolve();
                                                }),
                                            }}
                                        />
                                    </Container>
                                )
                            }
                        },
                        {
                            icon: SettingsOutlined,
                            openIcon: Settings,
                            tooltip: 'Search Params',
                            render: rowData => {
                                return (
                                    <Container maxWidth='lg'>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title='Search Params'
                                            columns={[
                                                {
                                                    title: 'Name',
                                                    field: 'name',
                                                },
                                                {
                                                    title: 'Value',
                                                    field: 'value'
                                                },
                                                {
                                                    title: 'Search Engine',
                                                    field: 'search_engine',
                                                    hidden: false,
                                                    initialEditValue: rowData.id
                                                }
                                            ]}
                                            data={rowData.search_params}
                                            options={{
                                                emptyRowsWhenPaging: false,
                                                search: false,
                                            }}
                                            localization={localization(t)}
                                            editable={{
                                                onRowAdd: newData => new Promise((resolve, reject) => {
                                                    this.props.addSearchParams(newData);
                                                    resolve();
                                                }),
                                                onRowDelete: oldData => new Promise((resolve, reject) => {
                                                    this.props.deleteSearchParams(oldData);
                                                    resolve();
                                                }),
                                                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                    const changed = deepCopy(oldData);
                                                    delete changed.tableData;
                                                    if (!isEqual(newData, changed)) {
                                                        this.props.editSearchParams(newData, oldData);
                                                    }
                                                    resolve();
                                                }),
                                            }}
                                        />
                                    </Container>
                                )
                            }
                        }
                    ]}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    esearch: state.esearchReducer.esearch,
    oci: state.esearchReducer.oci,
    companies: state.esearchReducer.companies,
    oci_elements: state.esearchReducer.oci_elements,
    search_elements: state.esearchReducer.search_elements
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getEngines,
    deleteEngine,
    editEngine,
    addEngine,
    getOCI,
    getCompanies,
    getSearchElements,
    getOCIElements,
    addElementsMapping,
    editElementsMapping,
    deleteElementsMapping,
    addSearchParams,
    editSearchParams,
    deleteSearchParams
})(withTranslation(['common', 'background_search'])(BackgroundSearch)));