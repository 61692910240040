import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Loading from './Loading';
import queryString from 'query-string';
import {loginUser} from '../../actions/auth';
import {directSearch} from "../../actions/esearch";

const PrivateRoute = ({component: Component, auth, loginUser, directSearch, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            const search = props.location.search;
            const searchParams = queryString.parse(search);
            if (searchParams.username && searchParams.password) {
                const username = searchParams.username;
                const password = searchParams.password;
                if (!auth.isAuthenticated){
                    loginUser({username, password});
                }
                if (searchParams.search_query) {
                    const search_query = searchParams.search_query;
                    directSearch(search_query);
                }
                return <Redirect to="/"/>
            }
            if (auth.isLoading) {
                return <Loading/>;
            } else if (!auth.isAuthenticated) {
                return <Redirect to="/login"/>;
            } else {
                return <Component {...props}/>;
            }
        }}
    />
);

const mapStateToProps = state => ({
    auth: state.authReducer
});

export default connect(mapStateToProps, {loginUser, directSearch})(PrivateRoute);