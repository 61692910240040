import {
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    EDIT_USER,
    GET_USERS,
    DELETE_USER,
    CLEAR_USERS
} from "../actions/types";

const initialState = {
    users: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_USERS: {
            return {
                users: []
            }
        }
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload]
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload)
            };
        case EDIT_USER:
            return {
                ...state,
                users: state.users.filter(user => user !== action.oldData).concat(action.newData)
            };
        case REGISTER_USER_FAIL:
        default:
            return state
    }
}